<template>
    <div class="annuaire__view">
        <h2>Logs de l'utilisateur</h2>
        <h4>{{user.cab}} <span>-</span> {{ user.login }} <span>- {{ user.email }}</span></h4>
        <div class="header">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher dans les logs" single-line hide-details></v-text-field>
        </div>        
        <v-data-table :headers="headers" :items="logs" :loading="loading" :search="search" :footer-props="{
    itemsPerPageOptions: [10,20,30]
  }" :sort-by="['created']" :sort-desc="[true]" class="elevation-1">
            <template v-slot:item.created="{ item }">
                <span :class="{'text-error' : item.lvl == 'error'}">{{ formatedDate(item.created) }}</span>
            </template>
            <template v-slot:item.lvl="{ item }">
                <span :class="{'text-error' : item.lvl == 'error'}">{{ item.lvl }}</span>
            </template>
            <template v-slot:item.code="{ item }">
                <span :class="{'text-error' : item.lvl == 'error'}">{{ item.code }}</span>
            </template>
            <template v-slot:item.msg="{ item }">
                <span :class="{'text-error' : item.lvl == 'error'}">{{ item.msg }}</span>
            </template>
            <template v-slot:item.detail="{ item }">
                <span :class="{'text-error' : item.lvl == 'error'}">{{ item.detail }}</span>
            </template>
        </v-data-table>
        <br>
        <div class="control">
            <v-btn color="#272727" class="white--text" @click="$router.push({path: '/user/' + $route.params.userId})" tile x-large>
                Retour
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { utils } from '../utils.js'

export default {
    name: 'Annuaire',
    data() {
        return {
            logs: [],
            loading: true,
            search: '',
            options: {
                page: 1,
                sortBy: ["created"],
                itemsPerPage: 15,
                sortDesc: [true],
            },
            headers: [
                { text: 'Création', value: 'created' },
                { text: 'Niveau', value: 'lvl' },
                { text: 'Code', value: 'code' },
                { text: 'Message', value: 'msg' },
                { text: 'Détail', value: 'detail' }
            ],
            user: {
                id: '',
                login: '',
                password: '',
                email: '',
                cab: ''
            }
        }
    },
    watch: {
        search: {
            handler() {
                this.loadData()
            }
        },
        options: {
            handler() {
                this.loadData()
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions("user", [
            "getUserLogAction",
            "getUserAction"
        ]),
        loadData() {
            var _this = this;
            this.loading = true;
            this.getUserLogAction(this.$route.params.userId)
            .then(res => {
                _this.logs = res.data
            })
            .finally(() => {
                _this.loading = false
            })
        },
        loadUser() {
            const _this = this
            this.getUserAction(this.$route.params.userId)
            .then(function (res) {
                _this.user.id = res.data.id
                _this.user.cab = res.data.cab
                _this.user.login = res.data.login
                _this.user.email = res.data.email
            })
            .catch(() => {
                _this.retour()
            })
        },
        formatedDate(date) {
            return utils.tools.formatedDate(date)
        }
    },
    created() {
        this.loadUser()
        this.loadData()
    }
}
</script>
<style lang="scss" scoped>
.annuaire__view {
    width: 80%;
    margin: 20px auto;
    .header {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .v-input {
            margin: 0;
            padding: 0;
        }
        .v-btn {
            margin: 0 0 0 20px;
        }
    }
    .text-error {
        color: #ff6363;
    }
    h4 {
        font-weight: normal;
        margin-bottom: 20px;
        width: 100%;
        margin: 0;
        span {
            font-size: 16px;
            color: #969696;
        }
    }
    .control {
        /*position: fixed;*/
        bottom: 0;
        width: 100%;
        z-index: 100;

        .v-btn {
            width: 50%;
        }
    }
    .flex__adder {
        display: flex;
        gap: 10%;
        form {
            width: 50%;
        }
    }
    form {
        .v-btn {
            margin: 0 0 10px 10px;
        }
        div {
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>